// MAIN

const MAIN_PATH = '/';

// AUTHENTICATION
const LOGIN_PATH = '/auth/login';

//USER MANAGEMENT
const USER_PATH = '/usermanagement';

//COMPANY MANAGEMENT
const COMPANY_ADD_PATH = '/companymanagement/approvedcompanies/add';
const APPROVED_COMPANIES_PATH = '/companymanagement/approvedcompanies';
const APPROVED_COMPANIES_DETAIL_PATH = '/companymanagement/approvedcompanies/detail';
const APPROVED_COMPANIES_EDIT_PATH = '/companymanagement/approvedcompanies/edit';
const PENDING_COMPANIES_PATH = '/companymanagement/pendingcompanies';
const PENDING_COMPANIES_DETAIL_PATH = '/companymanagement/pendingcompanies/detail';

//DRIVER MANAGEMENT
const APPROVED_DRIVERS_PATH = '/drivermanagement/approveddrivers';
const APPROVED_DRIVERS_DETAIL_PATH = '/drivermanagement/approveddrivers/detail';
const APPROVED_DRIVERS_EDIT_PATH = '/drivermanagement/approveddrivers/edit';
const PENDING_DRIVERS_PATH = '/drivermanagement/pendingdrivers';
const PENDING_DRIVERS_DETAIL_PATH = '/drivermanagement/pendingdrivers/detail';

//VEHICLE MANAGEMENT
const APPROVED_VEHICLES_PATH = '/vehiclemanagement/approvedvehicles';
const APPROVED_VEHICLES_DETAIL_PATH = '/vehiclemanagement/approvedvehicles/detail';
const APPROVED_VEHICLES_EDIT_PATH = '/vehiclemanagement/approvedvehicles/edit';
const PENDING_VEHICLES_PATH = '/vehiclemanagement/pendingvehicles';
const PENDING_VEHICLES_DETAIL_PATH = '/vehiclemanagement/pendingvehicles/detail';

//PROJECT MANAGEMENT
const APPROVED_PROJECTS_ADD_PATH = '/page/ProjectManagement/ApprovedProjects/add';
const APPROVED_PROJECTS_EDIT_PATH = '/page/ProjectManagement/ApprovedProjects/edit';
const APPROVED_PROJECTS_PATH = '/projectmanagement/approvedprojects';
const APPROVED_PROJECTS_DETAIL_PATH = '/projectmanagement/approvedprojects/detail';
const PENDING_PROJECTS_PATH = '/projectmanagement/pendingprojects';
const PENDING_PROJECTS_DETAIL_PATH = '/projectmanagement/pendingprojects/detail';

// SPOT MARKET
const SPOT_MARKET_PATH = '/spotmarket';

// SHIPMENT MANAGEMENT
const SHIPMENT_MANAGEMENT_PATH = '/';

// Shipment Detail
const SHIPMENT_DETAIL = '/shipment-detail/:id';

// Shipment Create and Update
const SHIPMENT_EDIT = '/shipment-edit/:id';

// Error Page
const ERROR_PATH = '/error';

export const ROUTES = {
  INDEX: { PATH: MAIN_PATH },
  AUTHENTICATION: {
    LOGIN: { PATH: LOGIN_PATH }
  },
  DASHBOARD: {
    USER_MANAGEMENT: {
      PATH: USER_PATH
    },
    COMPANY_MANAGEMENT: {
      COMPANY_ADD: { PATH: COMPANY_ADD_PATH },
      APPROVED_COMPANIES: { PATH: APPROVED_COMPANIES_PATH },
      APPROVED_COMPANIES_DETAIL: { PATH: APPROVED_COMPANIES_DETAIL_PATH },
      APPROVED_COMPANIES_EDIT: { PATH: APPROVED_COMPANIES_EDIT_PATH },
      PENDING_COMPANIES: { PATH: PENDING_COMPANIES_PATH },
      PENDING_COMPANIES_DETAIL: { PATH: PENDING_COMPANIES_DETAIL_PATH }
    },
    DRIVER_MANAGEMENT: {
      APPROVED_DRIVERS: { PATH: APPROVED_DRIVERS_PATH },
      APPROVED_DRIVERS_DETAIL: { PATH: APPROVED_DRIVERS_DETAIL_PATH },
      APPROVED_DRIVERS_EDIT: { PATH: APPROVED_DRIVERS_EDIT_PATH },
      PENDING_DRIVERS: { PATH: PENDING_DRIVERS_PATH },
      PENDING_DRIVERS_DETAIL: { PATH: PENDING_DRIVERS_DETAIL_PATH }
    },
    VEHICLE_MANAGEMENT: {
      APPROVED_VEHICLES: { PATH: APPROVED_VEHICLES_PATH },
      APPROVED_VEHICLES_DETAIL: { PATH: APPROVED_VEHICLES_DETAIL_PATH },
      APPROVED_VEHICLES_EDIT: { PATH: APPROVED_VEHICLES_EDIT_PATH },

      PENDING_VEHICLES: { PATH: PENDING_VEHICLES_PATH },
      PENDING_VEHICLES_DETAIL: { PATH: PENDING_VEHICLES_DETAIL_PATH }
    },
    PROJECT_MANAGEMENT: {
      APPROVED_PROJECTS_ADD: { PATH: APPROVED_PROJECTS_ADD_PATH },
      APPROVED_PROJECTS_EDIT: { PATH: APPROVED_PROJECTS_EDIT_PATH },
      APPROVED_PROJECTS: { PATH: APPROVED_PROJECTS_PATH },
      APPROVED_PROJECTS_DETAIL: { PATH: APPROVED_PROJECTS_DETAIL_PATH },
      PENDING_PROJECTS: { PATH: PENDING_PROJECTS_PATH },
      PENDING_PROJECTS_DETAIL: { PATH: PENDING_PROJECTS_DETAIL_PATH }
    },
    SPOT_MARKET: {
      PATH: SPOT_MARKET_PATH
    },
    SHIPMENT_MANAGEMENT: {
      PATH: SHIPMENT_MANAGEMENT_PATH,
      SHIPMENT_EDIT: { PATH: SHIPMENT_EDIT }
    }
  },
  SHIPMENT_DETAIL: { PATH: SHIPMENT_DETAIL },
  ERROR: { PATH: ERROR_PATH }
};
