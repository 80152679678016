import { SwipeableDrawer } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import PasswordChange from './change-password';
// import PasswordChange from './change-password';
import ProfileEdit from './profile-edit';
import ProfileView from './profile-view';
// import ProfileEdit from './profile-edit';
// import ProfileView from './profile-view';
import { getPageMode } from './reducer/selector';
import { reset } from './reducer/slice';

interface IProfile {
  changeDrawerStatus: (newDrawerStatus: boolean) => void;
  drawerStatus: boolean;
}

const Profile = (props: IProfile) => {
  const { changeDrawerStatus, drawerStatus } = props;

  const dispatch = useDispatch();

  const pageMode = useSelector(getPageMode);

  const toggleDrawer = () => {
    changeDrawerStatus(!drawerStatus);
    dispatch(reset());
  };

  return (
    <SwipeableDrawer anchor={'right'} open={drawerStatus} onClose={toggleDrawer} onOpen={toggleDrawer}>
      {pageMode === 'profileView' ? (
        <ProfileView changeDrawerStatus={changeDrawerStatus} drawerStatus={drawerStatus} />
      ) : pageMode === 'profileEdit' ? (
        <ProfileEdit />
      ) : pageMode === 'passwordChange' ? (
        <PasswordChange />
      ) : (
        'Bir hata oluştu.'
      )}
    </SwipeableDrawer>
  );
};

export default Profile;
