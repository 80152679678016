import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { reset } from '@/layouts/dashboard/profile/reducer/slice';

import { Index } from './types';

const initialState = {
  selectedProjectId: '',
  selectedStatusId: '',
  selectedIsDelivered: false,
  selectedDateOption: 'Tarih',
  startDate: '',
  endDate: ''
} as Index;

export const shipmentManagementSlice = createSlice({
  name: 'shipmentManagement',
  initialState,
  reducers: {
    resetFilters: () => initialState,
    setSelectedProjectId: (state, action: PayloadAction<string>) => {
      state.selectedProjectId = action.payload;
    },
    setSelectedStatusId: (state, action: PayloadAction<string>) => {
      state.selectedStatusId = action.payload;
    },
    setSelectedIsDelivered: (state, action: PayloadAction<boolean>) => {
      state.selectedIsDelivered = action.payload;
    },
    setSelectedDateOption: (state, action: PayloadAction<string>) => {
      state.selectedDateOption = action.payload;
    },
    setSelectedStartDate: (state, action: PayloadAction<string>) => {
      state.startDate = action.payload;
    },
    setSelectedEndDate: (state, action: PayloadAction<string>) => {
      state.endDate = action.payload;
    }
  }
});

export const {
  setSelectedProjectId,
  setSelectedStatusId,
  setSelectedIsDelivered,
  setSelectedDateOption,
  setSelectedEndDate,
  setSelectedStartDate,
  resetFilters
} = shipmentManagementSlice.actions;
export default shipmentManagementSlice.reducer;
