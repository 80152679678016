import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SavedCompanyInfo: {},
  SavedCurrentInfo: {},
  SavedOperationInfo: {},
  SavedUsersInfo: {},
  SavedPaymentInfo: {},
  CombinedInfo: {} // Initial state for CombinedInfo
};

export const companyAddSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    SavedCompanyInfo: (state, action) => {
      state.SavedCurrentInfo = {};
      state.SavedCompanyInfo = action.payload;
    },
    SavedCurrentInfo: (state, action) => {
      state.SavedOperationInfo = {};
      state.SavedCurrentInfo = action.payload;
    },
    SavedOperationInfo: (state, action) => {
      state.SavedUsersInfo = {};
      state.SavedOperationInfo = action.payload;
    },
    SavedUsersInfo: (state, action) => {
      state.SavedPaymentInfo = {};
      state.SavedUsersInfo = action.payload;
    },
    SavedPaymentInfo: (state, action) => {
      state.SavedPaymentInfo = action.payload;
    },
    CompineSaved: (state) => {
      state.CombinedInfo = {
        ...state.SavedCompanyInfo,
        ...state.SavedCurrentInfo,
        ...state.SavedOperationInfo,
        ...state.SavedUsersInfo,
        ...state.SavedPaymentInfo
      };
    }
  }
});

export const { SavedCompanyInfo, SavedCurrentInfo, SavedOperationInfo, SavedUsersInfo, SavedPaymentInfo, CompineSaved } =
  companyAddSlice.actions;
export default companyAddSlice.reducer;
