import { authApiService } from '../authApiService';
import * as dto from './dto';

const authApi = authApiService.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<dto.LoginResponseDto, dto.LoginRequestDto>({
      query: (body) => ({
        url: `/backoffice-user/login`,
        method: 'POST',
        body
      })
    }),

    me: build.query<dto.UserProfileResponseDto, void>({
      query: () => ({
        url: `/user/me`,
        method: 'GET'
      })
    }),
    getUserProfile: build.query<dto.UserProfileResponseDto, void>({
      query: () => ({
        url: `/backoffice-user/profile`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    updateUserProfile: build.mutation<void, dto.UpdateUserProfileRequestDto>({
      query: (body) => ({
        url: `/backoffice-user/profile`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    userChangePassword: build.mutation<void, dto.ChangePasswordRequestDto>({
      query: (body) => ({
        url: `/backoffice-user/profile/password`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  })
});

export const { useLoginMutation, useLazyMeQuery, useGetUserProfileQuery, useUpdateUserProfileMutation, useUserChangePasswordMutation } =
  authApi;
