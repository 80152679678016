import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { default as ArrowLeftIcon } from '@/assets/userProfile/arrow_left_icon.svg';
import { default as PersonIcon } from '@/assets/userProfile/person_icon.svg';
import InputPhone from '@/components/PhoneInput/phone-input';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '@/store/api/auth/api';
import { UpdateUserProfileRequestDto } from '@/store/api/auth/dto';

import { notifyError, notifySuccess } from '../../../components/notification/notify';
import { reset } from './reducer/slice';

// Validasyon şeması
const schema = yup
  .object({
    name: yup.string().required('Boş Bırakılamaz!'),
    phone: yup.string().required('Boş Bırakılamaz!')
  })
  .required();

const ProfileEdit = () => {
  const { data: userProfile } = useGetUserProfileQuery();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: userProfile?.name || '',
      phone: userProfile?.phone || ''
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const values = watch();

  const isFormValid = useMemo(() => {
    return values.name && values.phone;
  }, [values]);

  const onSubmit = async (data: UpdateUserProfileRequestDto) => {
    setOpen(true);
  };

  const handleSubmitForm = (data: UpdateUserProfileRequestDto) => {
    try {
      updateUserProfile(data);
      notifySuccess({ message: 'Kaydedildi', title: 'Başarılı' });
      dispatch(reset());
      handleClose();
      navigate('/');
    } catch (error) {
      // Hata durumunda yapılacaklar
      console.log('error', error);
      handleClose();
      notifyError({ message: 'Güncelleme yapılırken bir hata oluştu', title: 'Hata' });
    }
  };

  return (
    <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
      <Grid xs={12} item height="100%">
        <Stack direction="row" justifyContent="space-between" marginBottom="4px" alignItems="center">
          <Stack direction="row" spacing="13px" alignItems="center">
            <img src={PersonIcon} alt="PersonIcon" />
            <Typography variant="h6">Profil Düzenle</Typography>{' '}
          </Stack>
          <IconButton
            disableRipple
            onClick={() => {
              dispatch(reset());
            }}
          >
            <img src={ArrowLeftIcon} alt="LeftArrowIcon" />
            <Typography sx={{ opacity: '60%' }} variant="h6">
              Geri Dön
            </Typography>{' '}
          </IconButton>
        </Stack>

        <Stack direction="column" justifyContent="center" pt="52px">
          <Grid item xs={12} pb="60px">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Ad Soyad"
                  variant="standard"
                  size="medium"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ pb: 1 }}>
                        <img width={25} src={PersonIcon} alt="PersonIcon" />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} pb="60px">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputPhone
                  {...field}
                  inputProps={{
                    label: 'Telefon Numarası',
                    error: !!errors.phone,
                    helperText: errors.phone ? errors.phone.message : ''
                  }}
                />
              )}
            />
          </Grid>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item xs={4}>
            <Button disabled={!isFormValid} variant="outlined" onClick={handleSubmit(onSubmit)}>
              Kaydet
            </Button>
          </Grid>
        </Stack>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle justifyContent="center" id="alert-dialog-title">
          {'Profil Düzenle'}
        </DialogTitle>
        <DialogContent sx={{ color: '#212427' }}>Değişiklikler kaydedilecektir onaylıyor musunuz?</DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => {
              handleSubmitForm(values);
            }}
            type="submit"
          >
            Evet
          </Button>
          <Button sx={{ color: '#212427' }} onClick={handleClose}>
            Hayır
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ProfileEdit;
