//import './App.css'
import { Box, Container, Stack, styled, ThemeProvider } from '@mui/material';
import { Outlet, useNavigation } from 'react-router-dom';
import ProgressBar from 'react-topbar-progress-indicator';

import { dashboardTheme } from '../../theme/dashboard/index';
import Sidebar from './sidebar';

ProgressBar.config({
  barColors: {
    '0': '#F4BC2F',
    '1.0': '#F4BC2F'
  },
  shadowBlur: 5,
  barThickness: 4
});

function DashbordLayout() {
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar
  }));

  const navigation = useNavigation();

  return (
    <>
      {navigation.state !== 'idle' && <ProgressBar />}
      <ThemeProvider theme={dashboardTheme}>
        <Container
          disableGutters
          maxWidth={false}
          style={{
            marginLeft: -30
          }}
        >
          <Stack direction={'row'}>
            <Sidebar></Sidebar>
            <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
              <DrawerHeader />
              <Outlet />
            </Box>
          </Stack>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default DashbordLayout;
