import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CompanyByIdResponse } from '@/store/api/backOffice/dto';

interface State {
  company: CompanyByIdResponse; // Sadece company için özel tip veriyoruz
  [key: string]: unknown; // Diğer alanlar için dinamik ve esnek bir yapı
}

const initialState: State = {
  company: {} as CompanyByIdResponse, // Boş obje, company için başlangıç değeri
  SavedCompanyInfo: {}, // Tip belirtilmediği için any olur
  SavedCurrentInfo: {},
  SavedOperationInfo: {},
  SavedUsersInfo: {},
  SavedPaymentInfo: {},
  CombinedInfo: {}
};

export const companyEditSlice = createSlice({
  name: 'editcompany',
  initialState,
  reducers: {
    EditedCompanyInfo: (state, action: PayloadAction<CompanyByIdResponse>) => {
      state.company = action.payload;
    },
    SavedCompanyInfo: (state, action) => {
      state.SavedCurrentInfo = {};
      state.SavedCompanyInfo = action.payload;
    },
    SavedCurrentInfo: (state, action) => {
      state.SavedOperationInfo = {};
      state.SavedCurrentInfo = action.payload;
    },
    SavedOperationInfo: (state, action) => {
      state.SavedUsersInfo = {};
      state.SavedOperationInfo = action.payload;
    },
    SavedUsersInfo: (state, action) => {
      state.SavedPaymentInfo = {};
      state.SavedUsersInfo = action.payload;
    },
    SavedPaymentInfo: (state, action) => {
      state.SavedPaymentInfo = action.payload;
    },
    CompineSaved: (state) => {
      state.CombinedInfo = {
        ...(state.SavedCompanyInfo || {}),
        ...(state.SavedCurrentInfo || {}),
        ...(state.SavedOperationInfo || {}),
        ...(state.SavedUsersInfo || {}),
        ...(state.SavedPaymentInfo || {})
      };
    },
    ClearSaved: (state) => {
      state.SavedCompanyInfo = {};
      state.SavedCurrentInfo = {};
      state.SavedOperationInfo = {};
      state.SavedUsersInfo = {};
      state.SavedPaymentInfo = {};
    }
  }
});

export const {
  SavedCompanyInfo,
  SavedCurrentInfo,
  SavedOperationInfo,
  SavedUsersInfo,
  SavedPaymentInfo,
  CompineSaved,
  EditedCompanyInfo,
  ClearSaved
} = companyEditSlice.actions;

export default companyEditSlice.reducer;
