import { Components } from '@mui/material';

export const MuiMenuItem: Components = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: '10px',
        paddingBottom: '10px'
      }
    }
  }
};
