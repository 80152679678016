// src/slices/shipmentSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Shipment, ShipmentState } from './types';

const initialState: ShipmentState = {
  shipmentInfo: [],
  loadRoute: [],
  arrivalRoute: [],
  needRefetch: false
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    reset: () => initialState,
    setFtlInfo: (state, action: PayloadAction<Shipment[]>) => {
      state.shipmentInfo = action.payload;
    },
    returnFtlInfo: (state, action: PayloadAction<Shipment[]>) => {
      state.shipmentInfo = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setLoadRoute: (state, action: PayloadAction<any[]>) => {
      state.loadRoute = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setArrivalRoute: (state, action: PayloadAction<any[]>) => {
      state.arrivalRoute = action.payload;
    },
    needRefecth: (state) => {
      state.needRefetch = true;
    },
    resetRefetch: (state) => {
      state.needRefetch = false;
    }
  }
});

export const { setFtlInfo, setLoadRoute, setArrivalRoute, needRefecth, resetRefetch } = shipmentSlice.actions;

export default shipmentSlice.reducer;
