// MaterialUI
import { Collapse, CSSObject, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, Toolbar, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  CompanyInventoryIcon,
  CompanyManagementIcon,
  CustomerManagementIcon,
  DriverManagementIcon,
  OperationsCenterIcon,
  ProjectManagementIcon,
  RaportsIcon,
  SpotMarketIcon,
  TirboardIcon,
  TransportMethodIcon,
  UserManagementIcon,
  VehicleManagementIcon
} from '@/assets/icons/index';
import { default as ExpandLess } from '@/assets/sidebar/expand_less_icon.svg';
import { default as ExpandMore } from '@/assets/sidebar/expand_more_icon.svg';
//icons
import { default as Logo } from '@/assets/sidebar/logo.svg';
import { default as PinLogo } from '@/assets/sidebar/pin_logo_icon.svg';

import Navbar from './navbar';
import sidebar from './sidebar.json';

const IconMapObject = (menu: string): React.ReactNode => {
  switch (menu) {
    case 'CompanyInventory':
      return <img src={CompanyInventoryIcon} width={20} alt="CompanyInventory Icon" />;
    case 'OperationsCenter':
      return <img src={OperationsCenterIcon} width={20} alt="OperationsCenter Icon" />;
    case 'Tirboard':
      return <img src={TirboardIcon} width={20} alt="Tirboard Icon" />;
    case 'UserManagement':
      return <img src={UserManagementIcon} width={20} alt="UserManagement Icon" />;
    case 'SpotMarket':
      return <img src={SpotMarketIcon} width={20} alt="SpotMarket Icon" />;
    case 'DriverManagement':
      return <img src={DriverManagementIcon} width={20} alt="DriverManagement Icon" />;
    case 'VehicleManagement':
      return <img src={VehicleManagementIcon} width={20} alt="VehicleManagement Icon" />;
    case 'ProjectManagement':
      return <img src={ProjectManagementIcon} width={20} alt="projectManagement Icon" />;
    case 'CompanyManagement':
      return <img src={CompanyManagementIcon} width={20} alt="CompanyManagement Icon" />;
    case 'TransportManagement':
      return <img src={TransportMethodIcon} width={20} alt="TransportManagement Icon" />;
    case 'CustomerManagement':
      return <img src={CustomerManagementIcon} width={20} alt="CustomerManagement Icon" />;
    case 'Reports':
      return <img src={RaportsIcon} width={20} alt="Reports Icon" />;
    // default:
    //   return <img src={DefaultIcon} alt="Default Icon" />;
  }
};
const drawerWidth = 244;

//Drawer Styles and functions----------------------------------------
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: 60
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...theme.mixins.toolbar
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  position: 'fixed',
  zIndex: 3000,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function Sidebar(): JSX.Element {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState('');
  const navigate = useNavigate();
  function handleOpenCollapse(props: string): void {
    setOpenCollapse(props);
  }

  const location = useLocation();

  const [activeMenu, setMenuActive] = React.useState<string>('');

  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      setMenuActive('');
      return;
    }
    sidebar.forEach((menu) => {
      if (menu.url === path) {
        setMenuActive(menu.text);
      } else if (menu.submenu != null) {
        menu.submenu.forEach((submenu) => {
          if (submenu.url === path) {
            setMenuActive(submenu.text);
          }
        });
      }
    });
  }, [location]);

  return (
    <Box
      sx={{
        display: 'flex',
        marginRight: 25
      }}
    >
      <AppBar position="fixed" open={open} sx={{ bgcolor: theme.palette.common.white }}>
        <Toolbar>
          {/* Navbar companent is here!! */}
          <Navbar />
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
          setOpenCollapse('');
        }}
        variant="permanent"
        PaperProps={{
          sx: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.common.white
          }
        }}
      >
        <DrawerHeader
          sx={{
            justifyContent: open ? 'flex-start' : 'center',
            position: 'sticky',
            top: 0,
            background: theme.palette.common.white,
            pt: 4,
            pb: 2,
            zIndex: 10
          }}
        >
          <Link to="/">
            <Box
              src={open ? Logo : PinLogo}
              alt="TIRPORT"
              component="img"
              sx={{
                width: open ? 170 : '100%',
                height: 41.2,
                display: 'block',
                paddingLeft: open ? 7.5 : 0
              }}
            />
          </Link>
        </DrawerHeader>
        <List>
          {sidebar.map((menu, index) => (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                  textAlign: 'center'
                }}
              >
                <ListItemButton
                  sx={{
                    px: 3.9,
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    backgroundColor:
                      activeMenu === menu.text || (menu.submenu != null && menu.submenu.some((submenu) => submenu.text === activeMenu))
                        ? 'white !important'
                        : 'transparent !imprtant'
                  }}
                  disabled={menu.text.charAt(menu.text.length - 1) === '#'}
                  onClick={() => {
                    menu.submenu != null && handleOpenCollapse(menu.text);
                    menu.text === openCollapse && handleOpenCollapse('');
                    menu.submenu == null &&
                      navigate(menu.text.charAt(menu.text.length - 1) === '#' ? '#' : menu.url, {
                        replace: false
                      });
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {IconMapObject(menu.logo)}
                  </ListItemIcon>
                  <ListItemText
                    primary={menu.text}
                    sx={{
                      '& .MuiTypography-root': {
                        color: '#212427',
                        fontSize: '14px'
                      },
                      opacity: open ? 1 : 0
                    }}
                  />
                  {menu.submenu != null &&
                    open &&
                    (openCollapse === menu.text ? (
                      <img src={ExpandLess} alt="CompanyInventory Icon" />
                    ) : (
                      <img src={ExpandMore} alt="CompanyInventory Icon" />
                    ))}
                </ListItemButton>
              </ListItem>
              {menu.submenu != null && open && (
                <Collapse in={openCollapse === menu.text} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {menu.submenu.map((submenu, i) => (
                      <ListItemButton
                        disabled={submenu.text.charAt(submenu.text.length - 1) === '#'}
                        key={i}
                        sx={{ pl: 13 }}
                        onClick={() =>
                          navigate(submenu.text.charAt(submenu.text.length - 1) === '#' ? '#' : submenu.url, {
                            replace: false
                          })
                        }
                      >
                        <ListItemText
                          sx={{
                            '& .MuiTypography-root': {
                              color: '#212427',
                              fontSize: '13px'
                            },
                            opacity: open ? 1 : 0
                          }}
                          primary={submenu.text}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
