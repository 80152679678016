import { Grid, ThemeProvider } from '@mui/material';
import { Outlet, useNavigation } from 'react-router-dom';
import ProgressBar from 'react-topbar-progress-indicator';

import { Auththeme } from '@/theme/auth/index';

ProgressBar.config({
  barColors: {
    '0': '#F4BC2F',
    '1.0': '#F4BC2F'
  },
  shadowBlur: 5,
  barThickness: 4
});

export default function LoginLayout() {
  const navigate = useNavigation();

  return (
    <>
      {navigate.state !== 'idle' && <ProgressBar />}
      <ThemeProvider theme={Auththeme}>
        <Grid container direction="row">
          <Grid item xs={12} sx={{ backgroundColor: '#002E6D', overflow: 'hidden', height: '100vh' }}>
            <Outlet />
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
