import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { colorPalette } from './colorPalette';

const theme = createTheme();
responsiveFontSizes(theme);
const convertPxToRem = (pxValue: number, baseFontSize: number = 15): string => {
  return `${pxValue / baseFontSize}rem`;
};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  body3: React.CSSProperties;
  body4: React.CSSProperties;
}

export const typographyOptions = {
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  htmlFontSize: 18,
  fontSize: 14,
  h1: {
    fontSize: convertPxToRem(21),
    fontWeight: 'semibold',
    color: colorPalette.primary.main
  },
  h2: {
    fontSize: convertPxToRem(20),
    fontWeight: 'semibold',
    color: colorPalette.primary.main
  },
  h3: {
    fontSize: convertPxToRem(19),
    fontWeight: 'semibold',
    color: colorPalette.primary.main
  },
  h4: {
    fontSize: convertPxToRem(18),
    fontWeight: 'semibold',
    color: colorPalette.primary.main
  },
  h5: {
    fontSize: convertPxToRem(17),
    fontWeight: 'semibold',
    color: colorPalette.primary.main
  },
  h6: {
    fontSize: convertPxToRem(16),
    fontWeight: 'semibold',
    color: colorPalette.primary.main
  },
  body1: {
    color: colorPalette.primary.main
  },
  body2: {
    fontWeight: 'bold',
    color: colorPalette.primary.main,
    fontSize: convertPxToRem(15),
    marginBottom: convertPxToRem(5)
  },
  body3: {
    fontWeight: 700,
    color: colorPalette.primary.main,
    fontSize: convertPxToRem(14)
  },
  body4: {
    fontWeight: 500,
    color: colorPalette.primary.main,
    fontSize: convertPxToRem(14)
  },
  subtitle1: {
    color: colorPalette.primary.main
  },
  subtitle2: {
    fontWeight: 'bold',
    color: colorPalette.primary.main
  },
  caption: {
    color: colorPalette.primary.main,
    fontSize: convertPxToRem(14)
  },
  overline: {
    color: colorPalette.primary.main
  }
} as ExtendedTypographyOptions;
