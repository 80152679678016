import { createTheme } from '@mui/material/styles';

export const Auththeme = createTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
  },

  palette: {
    primary: {
      main: '#002E6D' // Sadece Typography bileşenlerinde kullanılacak renk
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Arka plan rengi
          color: '#002E6D', // Yazı rengi
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: 'white' // Fare üzerine gelindiğinde arka plan rengi
          },
          '&:disabled': {
            backgroundColor: 'white', // Devre dışı bırakıldığında arka plan rengi
            color: '#002E6D', // Devre dışı bırakıldığında yazı rengi
            opacity: 0.5
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#002E6D', // Seçilmemiş radio button rengi
          '&.Mui-checked': {
            color: '#002E6D' // Seçili radio button rengi
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#002E6D' // Yazı rengini buradan değiştirebilirsiniz
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#002E6D !important'
            },
            '&:hover fieldset': {
              borderColor: '#002E6D !important'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#002E6D !important'
            },
            '& .MuiSvgIcon-root': {
              fill: '#002E6D'
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottomColor: '#ffffff', // Normal durumda alt çizgi rengi
            borderBottomWidth: '2px' // Normal durumda alt çizgi kalınlığı
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: '#ffffff' // Fare üzerine gelindiğinde alt çizgi rengi
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#ffffff' // Tıkladığınızda alt çizgi rengi
          },
          '& .MuiInputBase-input': {
            color: '#ffffff' // Metin rengi
          }
        }
      }
    }
  }
});
