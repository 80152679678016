import { createSlice } from '@reduxjs/toolkit';

export interface CompanySelectedState {
  company: string | null;
  name: string | null;
}

const initialState: CompanySelectedState = {
  company: localStorage.getItem('selectedCompany') || null,
  name: localStorage.getItem('selectedCompanyName') || null
};

export const companySelectedSlice = createSlice({
  name: 'companySelected',
  initialState,
  reducers: {
    SET_COMPANY: (state, action) => {
      state.company = action.payload.id;
      state.name = action.payload.name;
      localStorage.setItem('selectedCompany', action.payload.id);
      localStorage.setItem('selectedCompanyName', action.payload.name);
    }
  }
});

export const { SET_COMPANY } = companySelectedSlice.actions;
export default companySelectedSlice.reducer;
