export default function Loader() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Group_3485"
        data-name="Group 3485"
        width="116.673"
        height="28.167"
        viewBox="0 0 116.673 28.167"
      >
        <path
          id="Path_1072"
          data-name="Path 1072"
          d="M271.76,1972.326a9.356,9.356,0,0,1,18.5-1.983,9.184,9.184,0,0,1-.461,5.224,52.235,52.235,0,0,1-4.008,8.229c-1.4,2.444-2.9,4.819-4.466,7.159-.134.2-.2.3-.383.03a96.521,96.521,0,0,1-7.486-12.931,18.169,18.169,0,0,1-1.6-4.753C271.795,1972.933,271.78,1972.56,271.76,1972.326Zm4.538-.084a4.824,4.824,0,1,0,4.842-4.783A4.811,4.811,0,0,0,276.3,1972.242Z"
          transform="translate(-208.992 -1962.986)"
          fill="#ffd100"
          fillRule="evenodd"
        />
        <g id="Group_650" data-name="Group 650" transform="translate(0 0.936)">
          <path
            id="Path_1073"
            data-name="Path 1073"
            d="M436.882,1972.1c-.092-.15-.24-.081-.361-.082-1.28,0-2.561-.008-3.84,0-.251,0-.362-.041-.359-.332.015-1.5.012-3,0-4.492,0-.244.055-.335.319-.335q7.226.014,14.454,0c.263,0,.32.089.318.334-.01,1.5-.014,2.994,0,4.49,0,.287-.1.339-.355.336-1.281-.011-2.56-.008-3.841,0-.119,0-.27-.072-.359.084q0,4.546-.008,9.094,0,4.313,0,8.627c0,.276-.058.367-.352.363-1.737,0-3.484-.006-5.219-.006-.312,0-.4-.074-.4-.4q.016-8.641.008-17.285C436.894,1972.362,436.887,1972.228,436.882,1972.1Z"
            transform="translate(-330.741 -1966.858)"
            fill="#002e6d"
            fillRule="evenodd"
          />
          <path
            id="Path_1074"
            data-name="Path 1074"
            d="M192.234,1978.484v-11.21c0-.2-.057-.4.287-.4,3.177.036,6.357-.078,9.532.071a8.487,8.487,0,0,1,4.6,1.274,7.149,7.149,0,0,1,3.061,5.162,9.145,9.145,0,0,1-.946,5.583,6.978,6.978,0,0,1-4.861,3.622,19.434,19.434,0,0,1-4.184.293c-.186,0-.251-.053-.251-.246q.008-2.39,0-4.783c0-.223.09-.257.28-.254a8.216,8.216,0,0,0,2.518-.216,2.281,2.281,0,0,0,1.759-1.868,2.658,2.658,0,0,0-.812-2.77,3.492,3.492,0,0,0-1.937-.624c-1.024-.088-2.053-.03-3.079-.045-.181,0-.23.071-.223.236.009.253,0,.508,0,.761q0,8.339.009,16.674c0,.385-.117.441-.467.438h-4.925c-.286,0-.364-.072-.364-.357q.013-5.672.006-11.345Z"
            transform="translate(-148.687 -1966.867)"
            fill="#002e6d"
            fillRule="evenodd"
          />
          <path
            id="Path_1075"
            data-name="Path 1075"
            d="M117.8,1972.077c1.027.015,2.055-.042,3.079.045a3.493,3.493,0,0,1,1.938.624,2.658,2.658,0,0,1,.812,2.77,2.28,2.28,0,0,1-1.759,1.868,8.326,8.326,0,0,1-2.538.181h-5.359c2.7,3.773,6.191,8.668,8.889,12.442a.446.446,0,0,0,.408.179c2.039,0,4.026.005,6.043,0a1.05,1.05,0,0,0,.446-.079l-6-7.581a6.913,6.913,0,0,0,4.612-3.56,9.147,9.147,0,0,0,.946-5.583,7.149,7.149,0,0,0-3.061-5.162,8.488,8.488,0,0,0-4.6-1.274c-3.175-.148-6.354-.034-9.532-.071-.343,0-.287.2-.287.4,0,1.6,0,3.2,0,4.792Z"
            transform="translate(-87.725 -1966.867)"
            fill="#002e6d"
            fillRule="evenodd"
          />
          <path
            id="Path_1076"
            data-name="Path 1076"
            d="M359.745,1972.077c1.027.015,2.055-.042,3.079.045a3.492,3.492,0,0,1,1.938.624,2.658,2.658,0,0,1,.812,2.77,2.28,2.28,0,0,1-1.759,1.868,8.326,8.326,0,0,1-2.538.181h-5.359c2.7,3.773,6.191,8.668,8.889,12.442a.445.445,0,0,0,.408.179c2.04,0,4.026.005,6.043,0a1.051,1.051,0,0,0,.447-.079c-2.068-2.61-3.918-4.947-6-7.581a6.913,6.913,0,0,0,4.611-3.56,9.146,9.146,0,0,0,.946-5.583,7.149,7.149,0,0,0-3.061-5.162,8.488,8.488,0,0,0-4.6-1.274c-3.175-.148-6.354-.034-9.532-.071-.344,0-.287.2-.287.4,0,1.6,0,3.2,0,4.792Z"
            transform="translate(-271.184 -1966.867)"
            fill="#002e6d"
            fillRule="evenodd"
          />
          <path
            id="Path_1077"
            data-name="Path 1077"
            d="M16.663,1972.1c-.092-.15-.24-.081-.361-.082-1.28,0-2.561-.008-3.84,0-.251,0-.362-.041-.359-.332.015-1.5.012-3,0-4.492,0-.244.055-.335.319-.335q7.226.014,14.454,0c.263,0,.32.089.318.334-.01,1.5-.014,2.994,0,4.49,0,.287-.1.339-.355.336-1.281-.011-2.56-.008-3.841,0-.119,0-.27-.072-.359.084q0,4.546-.009,9.094,0,4.313,0,8.627c0,.276-.058.367-.352.363-1.736,0-3.484-.006-5.219-.006-.312,0-.4-.074-.4-.4q.016-8.641.008-17.285C16.675,1972.362,16.668,1972.228,16.663,1972.1Z"
            transform="translate(-12.102 -1966.858)"
            fill="#002e6d"
            fillRule="evenodd"
          />
          <path
            id="Path_1078"
            data-name="Path 1078"
            d="M86.759,1978.559q0,5.635.006,11.27c0,.31-.065.41-.394.407-1.747,0-3.479,0-5.219,0-.31,0-.378-.1-.378-.388.01-7.539.014-15.008.009-22.545,0-.269.051-.378.355-.376h5.219c.351,0,.41.114.41.433-.011,3.756-.007,7.444-.007,11.2Z"
            transform="translate(-64.174 -1966.907)"
            fill="#002e6d"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </div>
  );
}
