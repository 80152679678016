import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { default as ArrowLeftIcon } from '@/assets/userProfile/arrow_left_icon.svg';
import { default as LockIcon } from '@/assets/userProfile/lock_icon.svg';
import { default as VisibilityOffYellowIcon } from '@/assets/userProfile/visibility_off_yellow_icon.svg';
import { default as VisibilityOnYellowIcon } from '@/assets/userProfile/visibility_on_yellow_icon.svg';
import { notifyError, notifySuccess } from '@/components/notification/notify';
import { useUserChangePasswordMutation } from '@/store/api/auth/api';
import { ChangePasswordRequestDto } from '@/store/api/auth/dto';

import { reset } from './reducer/slice';

const schema = yup.object().shape({
  currentPassword: yup.string().required('Boş Bırakılamaz!'),
  newPassword: yup.string().min(6, 'Yeni şifre en az 6 karakter olmalı').required('Boş Bırakılamaz!'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Yeni şifreler eşleşmiyor')
    .required('Boş Bırakılamaz!')
});

const PasswordChange = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmNewPasswordVisible, setIsConfirmNewPasswordVisible] = useState(false);
  const [changePassword] = useUserChangePasswordMutation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const values = watch();

  const isFormValid = useMemo(() => {
    return values.currentPassword && values.newPassword && values.confirmNewPassword;
  }, [values]);

  const onSubmit = () => {
    setOpen(true);
  };

  const handleSubmitForm = (data: ChangePasswordRequestDto) => {
    try {
      changePassword(data);
      notifySuccess({ message: 'Kaydedildi', title: 'Başarılı' });
      dispatch(reset());
      handleClose();
      navigate('/');
    } catch (error) {
      console.log('error', error);
      notifyError({ message: 'Güncelleme yapılırken bir hata oluştu', title: 'Hata' });
      handleClose();
    }
  };

  return (
    <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
      <Grid xs={12} item height="100%">
        <Stack direction="row" justifyContent="space-between" marginBottom="4px" alignItems="center">
          <Stack direction="row" spacing="13px" alignItems="center">
            <Typography variant="h6">Şifre Değiştir</Typography>
          </Stack>
          <IconButton
            disableRipple
            onClick={() => {
              dispatch(reset());
            }}
          >
            <img src={ArrowLeftIcon} alt="LeftArrowIcon" />
            <Typography sx={{ opacity: '60%' }} variant="h6">
              Geri Dön
            </Typography>
          </IconButton>
        </Stack>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" justifyContent="center" pt="52px">
            <Grid item xs={12} pb="60px">
              <FormControl fullWidth error={!!errors.currentPassword}>
                <Controller
                  name="currentPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Eski Şifre"
                      variant="standard"
                      size="medium"
                      type={isCurrentPasswordVisible ? 'text' : 'password'}
                      placeholder="Mevcut şifrenizi giriniz"
                      fullWidth
                      sx={{ label: { opacity: '60%' } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pb: 1 }}>
                            <img src={LockIcon} alt="PasswordIcon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pb: 1 }}>
                            <IconButton
                              onClick={() => {
                                setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
                              }}
                            >
                              {isCurrentPasswordVisible === true ? (
                                <img src={VisibilityOnYellowIcon} alt="VisibilityOffIcon" />
                              ) : (
                                <img src={VisibilityOffYellowIcon} alt="VisibilityOnIcon" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <FormHelperText>{errors.currentPassword?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} pb="60px">
              <FormControl fullWidth error={!!errors.newPassword}>
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Yeni Şifre"
                      variant="standard"
                      type={isNewPasswordVisible ? 'text' : 'password'}
                      size="medium"
                      fullWidth
                      placeholder="En az 6 haneli yeni şifrenizi girin"
                      sx={{ label: { opacity: '60%' } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pb: 1 }}>
                            <img src={LockIcon} alt="PersonIcon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pb: 1 }}>
                            <IconButton
                              onClick={() => {
                                setIsNewPasswordVisible(!isNewPasswordVisible);
                              }}
                            >
                              {isNewPasswordVisible === true ? (
                                <img src={VisibilityOnYellowIcon} alt="VisibilityOffIcon" />
                              ) : (
                                <img src={VisibilityOffYellowIcon} alt="VisibilityOnIcon" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <FormHelperText>{errors.newPassword?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} pb="60px">
              <FormControl fullWidth error={!!errors.confirmNewPassword}>
                <Controller
                  name="confirmNewPassword"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Yeni Şifre Tekrar"
                      variant="standard"
                      type={isConfirmNewPasswordVisible ? 'text' : 'password'}
                      size="medium"
                      fullWidth
                      placeholder="En az 6 haneli yeni şifrenizi tekrar girin"
                      sx={{ label: { opacity: '60%' } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pb: 1 }}>
                            <img src={LockIcon} alt="PersonIcon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ pb: 1 }}>
                            <IconButton
                              onClick={() => {
                                setIsConfirmNewPasswordVisible(!isConfirmNewPasswordVisible);
                              }}
                            >
                              {isConfirmNewPasswordVisible === true ? (
                                <img src={VisibilityOnYellowIcon} alt="VisibilityOffIcon" />
                              ) : (
                                <img src={VisibilityOffYellowIcon} alt="VisibilityOnIcon" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
                <FormHelperText>{errors.confirmNewPassword?.message}</FormHelperText>
              </FormControl>
            </Grid>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item xs={4}>
              <Button disabled={!isFormValid} type="submit" variant="outlined">
                Kaydet
              </Button>
            </Grid>
          </Stack>
        </form>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle justifyContent="center" id="alert-dialog-title">
          {'Şifre Değiştir'}
        </DialogTitle>
        <DialogContent sx={{ color: '#212427' }}>Değişiklikler kaydedilecektir onaylıyor musunuz?</DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => {
              handleSubmitForm(values);
            }}
            type="submit"
          >
            Evet
          </Button>
          <Button sx={{ color: '#212427' }} onClick={handleClose}>
            Hayır
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default PasswordChange;
