import 'react-phone-input-2/lib/style.css';

import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import tr from 'react-phone-input-2/lang/tr.json';

export default function InputPhone({ ...props }: PhoneInputProps) {
  return (
    <PhoneInput
      country="tr"
      localization={tr}
      enableSearch
      enableAreaCodeStretch
      countryCodeEditable={false}
      preferredCountries={['tr']}
      disableSearchIcon
      searchPlaceholder="Ara"
      inputStyle={{
        width: '100%',
        height: '44px',
        maxWidth: '630px'
      }}
      {...props}
    />
  );
}
